




import { Component, Vue } from 'vue-property-decorator';
  @Component({
    name: 'Home',
    components: {
    },
  })
export default class Home extends Vue {
  created () {
    const homePage = localStorage.getItem('homePage');
    if (homePage) {
      window.location.href = homePage;
    } else {
      this.$router.push('/work-platform-home').catch(() => { });
    }
  }
}
